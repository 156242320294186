import React, { ReactElement } from 'react'

interface Props {
  size: number
  fill: string
  id?: string
}

const Logo = (props: Props): ReactElement => {
  const { size, fill, id } = props
  return (
    <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 70 70" version="1.1" id={id}>
      <g id="Aug-3-Sprint" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Main-Landing-page" transform="translate(-125.000000, -21.000000)" fill={fill}>
          <path
            d="M195,21 L195,91 L125,91 L125,21 L195,21 Z M144.787704,36.9782609 L139.456522,36.9782609 L149.898098,55.9949049 L139.456522,75.0132473 L144.809783,75.0132473 L160.280231,46.5061141 L161.518342,48.8940217 L147.314878,75.0132473 L173.311821,75.0132473 L170.689538,70.2170516 L155.190217,70.2170516 L173.311821,36.9782609 L168.028193,36.9782609 L164.147418,44.1097147 L160.3125,36.9782609 L156.384171,44.1097147 L152.596807,36.9782609 L147.313179,36.9782609 L153.765285,48.9025136 L152.503397,51.2411685 L144.787704,36.9782609 Z M180.991848,36.9782609 L175.891644,36.9782609 L159.111753,67.4826766 L164.415761,67.4826766 L168.029891,60.6773098 L175.861073,75.0132473 L180.991848,75.0132473 L170.728601,55.9949049 L180.991848,36.9782609 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </svg>
  )
}

export default Logo
