import React from 'react'

import './Footer.scss'
import logoBackground from '../../assets/svg/alisi-logo-transparent.svg'
import Logo from '../Logo/Logo'

const SubscribeModal = ({ message, title, onClose }: any): JSX.Element => {
  return (
    <>
      <div className="subscribe-modal-wrapper fade-in dl-2" />
      <div className="modal-wrap">
        <div className="subscribe-modal fade-in-bottom dl-4">
          <img src={logoBackground} className="logo-transparent" alt="alisi logo background" />
          <div className="modal-content">
            <button type="button" className="close-modal" onClick={onClose}>
              <i className="la la-times" />
            </button>
            <Logo fill="#fff" size={70} />
            <h1>{title || 'Subscribe to our Newsletter'}</h1>
            <p>{message}</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default SubscribeModal
