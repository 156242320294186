import React, { ReactElement, useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import jsonp from 'jsonp'
import ReCAPTCHA from 'react-google-recaptcha'

import './Footer.scss'
import ayalaLogo from '../../assets/images/Ayala_Land_logo.png'
import SubscribeModal from './SubsribeModal'
import { isEmail } from '../Helpers/helpers'

const Footer: React.FC = (): ReactElement => {
  const [terms, setTerms] = useState<boolean>(false)
  const [modal, showModal] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [captcha, setCaptcha] = useState<boolean>(false)
  const [submit, setSubmitting] = useState<boolean>(false)

  const linkData = useStaticQuery(graphql`
    query footerSocialLinks {
      socialMediaLinks {
        links {
          id
          link
          category
          name
        }
      }
    }
  `)

  const socialMediaList = ['facebook', 'instagram', 'youtube', 'twitter']
  const socialLinks =
    linkData && linkData.socialMediaLinks
      ? linkData.socialMediaLinks.links
          .filter((x: any) => {
            return !x.name.includes('Career')
          })
          .filter((x: any) => {
            return socialMediaList.includes(x.name.toLowerCase())
          })
      : []

  const toggleTerms = (): void => {
    setMessage('Sorry that email is already subscribe.')
    setTerms(!terms)
  }

  const resetSubscribeInput = (): void => {
    const si: any = document.getElementById('subscribe-input')
    si.value = ''
  }

  const getAjaxUrl = (url: string): string => url.replace('/post?', '/post-json?')

  const subscribeToNewsLetter = (): void => {
    setSubmitting(true)
    const listID = 'f44f103cdf'
    const formID = '688e6ce9d27524bbaef1c9ed2'

    const url = getAjaxUrl(`https://atayala.us19.list-manage.com/subscribe/post?u=${formID}&id=${listID}&EMAIL=${email}`)

    jsonp(
      url,
      {
        param: 'c'
      },
      (err: any, data: any) => {
        if (err) {
          setError('Subscription failed, please try again later.')
        } else if (data.result !== 'success') {
          setError('This email is already subscribed.')
        } else {
          setMessage('You have successfully subscribed to Ayala Land International Sales, Inc. newsletter.')
          showModal(true)
          resetSubscribeInput()
        }
      }
    )
    setSubmitting(false)
  }

  const subscribe = (): void => {
    setError('')
    if (email.trim() !== '') {
      if (isEmail(email)) {
        if (terms && captcha) {
          subscribeToNewsLetter()
        } else {
          setError('Please accept the Terms and Conditions and complete the ReCAPTCHA to proceed.')
        }
      } else {
        setError('Please provide a proper e-mail format. Example: (user@email.com)')
      }
    } else {
      setError('Please provide an e-mail address.')
    }
  }

  return (
    <>
      {modal && (
        <SubscribeModal
          message={message}
          title="Thank you for subscribing!"
          onClose={(): void => {
            showModal(false)
          }}
        />
      )}
      <footer>
        <div className="footer-container">
          <div className="footer-newsletter">
            <div className="news-letter-header">
              <h1>
                Subscribe to get our
                <br />
                latest updates
              </h1>
            </div>
            <div className="news-letter-form">
              <div className="subform-wrapper">
                <div className="subscribe-form">
                  <input
                    type="text"
                    placeholder="Enter your e-mail address"
                    id="subscribe-input"
                    onKeyUp={(e: any): void => {
                      setEmail(e.target.value)

                      if (e.keyCode === 13) {
                        subscribe()
                      }
                    }}
                  />
                  <button type="button" onClick={subscribe}>
                    {submit ? 'Submitting..' : 'Submit'}
                  </button>
                </div>
                {error && (
                  <p className="footer-subscribe-error fade-in">
                    <i className="la la-exclamation-circle" /> {error}
                  </p>
                )}
                <div className="sub-terms">
                  <div className="terms-checkbox">
                    <i className={`la la-${terms ? 'check-square' : 'stop'} la-2x`} onClick={toggleTerms} />
                    <span>
                      I have read and agreed with the <a href="/privacy-notice">Privacy Notice</a> and{' '}
                      <a href="/terms-and-conditions">Terms and Conditions</a>
                    </span>
                  </div>
                  <ReCAPTCHA
                    sitekey="6LdPbLsZAAAAAKfFgRSCPS4hpp9-YeKDzrfWhsbr"
                    onChange={(): void => {
                      setCaptcha(true)
                    }}
                    onExpired={(): void => {
                      setCaptcha(false)
                    }}
                    onErrored={(): void => {
                      setCaptcha(false)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="footer-info">
            <div className="logo-social-links">
              <img src={ayalaLogo} alt="ayala land logo" />

              <div className="social-links" id="web-social-links">
                {socialLinks &&
                  socialLinks.map((item: { link: string; name: string }): JSX.Element | void => {
                    if (item.name !== '') {
                      return (
                        <a href={item.link} target="_blank" rel="noreferrer" key={Math.random()} title={item.link}>
                          <i className={`la la-${item.name.toLocaleLowerCase()}`} />
                        </a>
                      )
                    }
                  })}
              </div>
            </div>

            <div className="foot-links foot-nav-web">
              <span className="foot-link-header">Navigation</span>
              <a href="/about-us">About us</a>
              <a href="/contact-us">Contact us</a>
              <a href="/careers">Careers</a>
            </div>

            <div className="foot-links no-header foot-nav-web">
              <a href="/privacy-notice">Privacy Notice</a>
              <a href="/terms-and-conditions">Terms and Conditions</a>
            </div>

            <div id="footer-nav-links">
              <div className="foot-links">
                <span className="foot-link-header">Navigation</span>
                <a href="/about-us">About us</a>
                <a href="/contact-us">Contact us</a>
                <a href="/careers">Careers</a>
              </div>

              <div className="foot-links no-header">
                <a href="/privacy-notice">Privacy Notice</a>
                <a href="/terms-and-conditions">Terms & Conditions</a>
              </div>
            </div>

            <div className="foot-links">
              <span className="foot-link-header">Get In Touch</span>
              <span className="footer-text">
                We have offices located in countries outside of the Philippines. If you wish to pay us a visit, come see us in these
                addresses:
              </span>
              <a href="https://www.atayala.com/contact-us/international-offices">
                <strong>International Offices</strong>
              </a>
            </div>

            <div className="logo-social-links" id="mobile-social-links">
              <div className="social-links">
                {socialLinks &&
                  socialLinks.map((item: { link: string; name: string }) => {
                    return (
                      <a href={item.link} target="_blank" rel="noreferrer" key={Math.random()} title={item.link}>
                        <i className={`la la-${item.name.toLocaleLowerCase()}`} />
                      </a>
                    )
                  })}
              </div>
            </div>
          </div>

          <div className="foot-notes">
            <span>© 2020 Ayala Land International Sales Inc.</span>
            <a href="https://whitecloak.com" target="_blank" rel="noreferrer">
              <small>Designed by White Cloak Technologies</small>
            </a>
          </div>
        </div>
      </footer>
    </>
  )
}
export default Footer
